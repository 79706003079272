<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Dossiers SEED</li>
                </ol>
                <h2>{{ dossier.name }}</h2>
                <div class="">
                    <div class="dropdown">
                        <a class="dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Actions</a>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#" @click="validateStep()">Valider cette etape</a></li>
                            <li><router-link class="dropdown-item" :to="{name:'admin_seed_plan', params: {tkn: dossier.token}}">Afficher le plan d'actions</router-link></li>
                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#bmModal">Afficher le modele economique</a></li>
                            <li><a class="dropdown-item" href="#" type="button" data-bs-toggle="modal" data-bs-target="#consultantModal">Affecter a un consultant</a></li>
                            <li v-if="dossier.active"><a class="dropdown-item" href="#" @click="disable">Bloquer ce dossier</a></li>
                            <li v-else><a class="dropdown-item" href="#" @click="enable">Débloquer ce dossier</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="row">
                    <div id="side1" class="col-md-12 col-sm-12" >
                        <div class="card">
                            <div class="card-body">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-12">
                                            <div style="height: auto; ">
                                                <img style="width: 100%; max-height: 240px;" :src="dossier.photo" alt="">
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-12 infos">
                                            <span class="badge badge-success">ETAPE {{ dossier.step }}</span>
                                            <p>PROMOTEUR : <span class="text-bold">{{ dossier.owner?dossier.owner.name:'-' }}</span></p>
                                            <p>CONSULTANT : <span class="text-bold">{{ dossier.consultant?dossier.consultant.name:'-' }}</span></p>
                                            <p>DATE DE CREATION : <span class="value"> {{ dossier.created }}</span></p>
                                            <p><span>PAYS : </span> {{ dossier.pays?dossier.pays.name:'-' }}</p>
                                            <p>SECTEUR :  <span class="text-bold">{{ dossier.secteur?dossier.secteur.name:'-' }}</span></p>
                                            <span v-if="dossier.active" class="badge bg-success">EN LIGNE</span>
                                            <span v-else class="badge bg-danger">bloqué</span>
                                            <input type="hidden" id="pl_id" value="{{ dossier.plan_id }}"/>

                                            
                                        </div>
                                        <div class="col-md-4 col-sm-12">
                                            <div class="card bg-success" style="max-height:220px; overflow:scroll;">
                                                <div class="card-body">
                                                    <p>{{ dossier.description }}</p>
                                                    <p>
                                                        <strong>COHORTES : </strong>
                                                        <ul class="list-inline">
                                                            <li v-for="(cohorte,index) in dossier.cohortes" :key="index" class="list-inline-item">
                                                                <span class="badge bg-warning">{{ cohorte.name }} : <strong>{{ cohorte.programme.name }}</strong></span>
                                                            </li>
                                                        </ul>
                                                    </p>
                                                    <p>
                                                        <a data-bs-target="#cohorteModal" data-bs-toggle="modal" class="btn btn-sm btn-danger" href="">Integer un programme</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div id="side2" style="overflow: scroll" class="col-md-12 col-sm-12">
                        <div class="card p-2">
                            <div class="card-body">
                                <Seed :dossier="dossier" :choices="choices" />
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="consultantModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <form @submit.prevent="saveConsultant()" method="post">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">AFFECTER A UN CONSULTANT</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="close" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <select class="form-control" name="" @change="setConsultant($event)" required id="">
                        <option value="">SELECTIONNER ...</option>
                        <option v-for="(opt,index) in consultants" :key="index" :value="opt.id">{{ opt.first_name }} {{ opt.last_name }}</option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-success">Valider</button>
            </div>
            </div>
            </form>
        </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="cohorteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <form @submit.prevent="addCohorte()" method="post">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">INTEGRER UN PROGRAMME</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="close" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <select class="form-control" name="" @change="setProgramme($event)" required id="">
                        <option value="">SELECTIONNER UN PROGRAMME...</option>
                        <option v-for="(opt,index) in programmes" :key="index" :value="opt.id">{{ opt.name }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <select class="form-control" name="" @change="setCohorte($event)" required id="">
                        <option value="">SELECTIONNER UNE COHORTE ...</option>
                        <option v-for="(opt,index) in cohortes" :key="index" :value="opt.id">{{ opt.name }}</option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-success">ENREGISTRER</button>
            </div>
            </div>
            </form>
        </div>
        </div>
    </main>





</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import Seed from "../../Includes/Seed.vue";
export default {
    name:"IndexSseed",
    props: ['tkn'],
    components:{
        Seed,
    },
    data(){
        return {
            user:this.$store.state.auth.user,
            dossier:{},
            consultants:[],
            choices:[],
            programmes:[],
            cohortes:[],
            programme_id:0,
            cohorte_id:0,
            description:null,
            toaster: createToaster({ position:'top-right'}),
            consultant_id:0,
            componentKey: 0,
        }
    },
    methods:{
        setConsultant(event){
            console.log(event)
            this.consultant_id = event.target.value;
        },
        async setProgramme(event){
            console.log(event)
            this.programme_id = event.target.value;
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.get(this.path+`programme/cohortes/${this.programme_id}`)
                        .then((res)=>{
                            this.cohortes = res.data;
                        })
                        .catch(()=>{
                            this.toaster.error("Echec de connexion lors du chargement des cohortes !!!");
                        })
                        .finally(()=>{
                            loader.hide();
                        })
        },
        async disable(){
            await axios.get(this.path+'revendeur/seed/disable/'+this.tkn)
                        .then(()=>this.reload())
                        .catch((err)=>console.error(err));
        },
        async enable(){
            await axios.get(this.path+'revendeur/seed/enable/'+this.tkn)
                        .then(()=>this.reload())
                        .catch((err)=>console.error(err));
        },
        setCohorte(event){
            console.log(event)
            this.cohorte_id = event.target.value;
        },
        async addCohorte(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.post(this.path+'revendeur/seed/cohorte',{cohorte_id:this.cohorte_id,dossier_id:this.dossier.id,owner_id:this.dossier.owner.id})
                        .then(()=>{
                            this.reload();
                        })
                        .catch(()=>{
                            this.toaster.error("Cette operation a echoue !!!");
                        })
                        .finally(()=>{
                            loader.hide();
                            document.getElementById('close').click();
                        })
        },
        async saveConsultant(){
            let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });
            await axios.post(this.path+'revendeur/seed/consultant',{token:this.dossier.token,consultant_id:this.consultant_id})
                        .then(()=>{
                            this.reload();
                        })
                        .catch(()=>{
                            this.toaster.error("Cette operation a echoue !!!");
                        })
                        .finally(()=>{
                            loader.hide();
                            document.getElementById('close').click();
                        })
        },
        forceRender() {
            this.componentKey += 1;
        },
        
        async validateStep(){
            await axios.get(this.path+'revendeur/seeds/validate/step/'+this.tkn)
                        .then(()=>this.reload())
                        .catch((err)=>console.error(err));
        },
        setData(data){
            this.consultants = data.consultants;
            this.dossier = data.dossier;
            this.choices = data.choix;
            this.programmes = data.programmes;
        },
        async load(){
            let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    //onCancel: this.onCancel,
                });
            await axios.get(this.path+'revendeur/seeds/'+this.tkn)
                        .then((res)=>this.setData(res.data))
                        .catch((err)=>console.error(err))
                        .finally(()=>loader.hide());
        },
        async reload(){
            await axios.get(this.path+'revendeur/seeds/'+this.tkn)
                        .then((res)=>{
                            this.setData(res.data)
                            this.toaster.success("Operation effectuee avec succes !!!");
                        })
                        .catch((err)=>console.error(err));
        }
    },
    mounted(){
        this.load().then(()=>console.log(this.dossier))
        
    }

}
</script>

<style scoped>
    .badge{
        background-color: #3d9970;
        color: #fff;
    }
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: var(--bs-nav-pills-link-active-color);
        background-color: #3d9970;
    }
    .nav-pills .nav-link:hover {
        color: #3d9970;
        
    }
    .infos p{
        margin-bottom: 2px;
        font-size: smaller;
    }
</style>