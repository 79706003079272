<template>
    <main class="main">
        <!-- ======= Breadcrumbs ======= -->
        <section id="breadcrumbs" class="breadcrumbs">
            <div class="container">
                <ol>
                <li><a href="/">ACCUEIL</a></li>
                <li>Deal flow</li>
                </ol>
                <h2>MON DEAL FLOW</h2>
            </div>
        </section><!-- End Breadcrumbs -->
        <div class="container">
            <div class="mb-2">
                <input @input="search" type="text" style="max-width: 400px;" class="form-control p-1 bg-success-subtle" placeholder="Rechercher ...">
            </div>

            <div class="table-responsive">
                <table class="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th>DATE</th>
                        <th>DOSSIER</th>
                        <th>TYPE</th>
                        <th>PORTEUR DE PROJET</th>
                        <th>INVESTISSEUR <span v-if="leader" class="badge bg-info text-end">Leader</span></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(deal,index) in filtered_deals" :key="index">
                        <td>{{ deal.date }}</td>
                        <td>{{ deal.dossier.name }} <span class="badge bg-warning" v-if="deal.dossier.closed">dossier clos</span></td>
                        <td>{{ deal.type }}</td>
                        <td>{{ deal.owner.first_name }} {{ deal.owner.last_name }}</td>
                        <td>{{ deal.investisseur.first_name }} {{ deal.investisseur.last_name }} &nbsp; <span class="badge bg-success float-end" v-if="deal.leader">leader</span></td>
                        <td>
                            <div v-if="!deal.dossier.closed" class="dropdown">
                                <a class="dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Actions</a>
                                <ul class="dropdown-menu">
                                    <li v-if="deal.leader"><a class="dropdown-item" href="#" @click="close({token:deal.token})">CLOTURER L'OPERATION</a></li>
                                    <li><a class="dropdown-item" href="#" @click="setId({id:deal.id})" type="button" data-bs-toggle="modal" data-bs-target="#bmModal">AJOUTER UN DOCUMENT</a></li>
                                    <li v-if="!deal.leader"><a class="dropdown-item" href="#" @click="setLeader({token:deal.token})">DESIGNER COMME LEADER</a></li>
                                    <li><a class="dropdown-item" href="#" @click="setId({id:deal.id})" type="button" data-bs-toggle="modal" data-bs-target="#formModal">FORMULAIRE D'ENQUETE</a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    </main>
    <div class="modal fade" id="bmModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">AJOUT D'UN DOCUMENT</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" id="close11" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form enctype="multipart/form-data" class="form"  @submit.prevent="submit" method="post">
            
                        <div class="form-group">
                            <label for="">Nom du document</label>
                            <input type="text" class="form-control" v-model="filename">
                        </div>
                        <div class="form-group">
                            <label for="">Fichier pdf</label>
                            <input type="file" class="form-control" name="file" id="file" @change="fileChange">
                        </div>
                        <button class="btn btn-success" data-bs-dismiss="modal" type="submit">ENREGISTRER</button>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="formModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Formulaire de compliance</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" id="close11" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form enctype="multipart/form-data" class="form"  @submit.prevent="submitForm" method="post">
                        
                        <div v-for="(question,index) in questions" :key="index" class="form-group">
                            <label for="">{{ question.name }}</label>
                            <input v-if="index==0" type="file" class="form-control" @change="pieceChange">
                            <input v-else type="text" class="form-control" v-model="formulaire.reponses[question.id]">
                        </div>
                        <button class="btn btn-success" data-bs-dismiss="modal" type="submit">ENREGISTRER</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name:"IndexPorteur",
    data(){
        return {
            user:this.$store.state.auth.user,
            toaster: createToaster({ position:'top-right'}),
            questions:[],
            deals:[],
            filtered_deals:[],
            current_id:0,
            current_type:'',
            file:null,
            filename:'',
            formulaire:{
                reponses:[],
                piece:null,
            },
        }
    },
    methods:{
        fileChange(event){
            this.file = event.target.files[0];
        },
        pieceChange(event){
            this.formulaire.piece = event.target.files[0];
        },
        async setId(elt){
            this.current_id = elt.id;
        },
        async submit(){
            let loader = this.$loading.show();
            let form = new FormData();
            if(this.file!=null){
                form.append("fichier",this.file);
                form.append("filename",this.filename);
                form.append("id",this.current_id)
            }
            await axios.post(this.path+'consultant/deal/document',form,{
                        headers:{
                            'Accept':'multipart/form-data'
                        }
                    })
                    .then(()=>this.load())
                    .catch((err)=>console.error(err))
                    .finally(()=>loader.hide());
        },
        async submitForm(){
            let loader = this.$loading.show();
            let form = new FormData()
            form.append("id",this.current_id);
            form.append("piece",this.formulaire.piece);
            let reps = this.formulaire.reponses.map(function(v,k){return {question_id:k,reponse:v}})
            form.append('reponses',JSON.stringify(reps));
            console.log(reps);
            await axios.post(this.path+'consultant/deal/form',form,{
                        headers:{'Accept':'multipart/form-data'}
                    })
                    .then(()=>this.load())
                    .catch((err)=>console.error(err))
                    .finally(()=>loader.hide());
        },
        async setLeader(elt){
            //console.log(type);
            await axios.get(this.path+'consultant/deal/leader/'+elt.token)
                        .then(()=>{
                            this.load();
                        })
                        .catch((err)=>console.error(err));
        },
        async load(){
            await axios.get(this.path+'consultant/deals')
                        .then((res)=>{
                            this.deals = res.data.items
                            this.filtered_deals = res.data.items
                            this.questions = res.data.questions
                        })
                        .catch((err)=>console.error(err));
        },
        async close(elt){
            //console.log(type);
            await axios.get(this.path+'consultant/deal/close/'+elt.token)
                        .then(()=>{
                            this.load();
                        })
                        .catch((err)=>console.error(err));
        },

        search(event){
            {
                setTimeout(() => {
                    if (!event.target.value.trim().length) {
                        this.filtered_deals = [...this.deals];
                    } else {
                        //console.log(this.porteurs)
                        this.filtered_deals = this.deals.filter((item) =>item.dossier.name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.owner.last_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.owner.first_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.investisseur.last_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                            || item.investisseur.first_name.toLowerCase().startsWith(event.target.value.toLowerCase())
                        );
                    }
                }, 250);
            }
        },

    },
    mounted(){
        this.load().then(()=>console.log(this.porteurs))
    }

}
</script>

<style scoped>
    .breadcrumbs a{
        color: #3d9970;
        text-decoration: none;
    }
</style>